import instance from "../../config";

export const SearchTickets = (from, to, date) => {
  return async (dispatch, getState) => {
    try {
      const { data } = await instance.get(
        `v2/search-ticket?berangkat=${from.id}&tujuan=${to.id}&end_date=${date}`,
        {}
      );
      localStorage.setItem("from", JSON.stringify(from));
      localStorage.setItem("to", JSON.stringify(to));
      localStorage.setItem("date", date);
      dispatch({
        type: "FETCH_TICKETS",
        payload: {
          tickets: data,
          from,
          to,
          date,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const FilterTickets = (type, time_start, time_end) => {
  return async (dispatch, getState) => {
    try {
      const { tickets } = getState();
      const { data } = await instance.get(
        `/search-ticket?berangkat=${tickets.selectedFrom.id}&tujuan=${tickets.selectedTo.id}&end_date=${tickets.selectedDate}`,
        {}
      );
      let filterTickets = [];
      if (type === "departure") {
        filterTickets = data.filter(
          (e) => e.jam_berangkat >= time_start && e.jam_berangkat <= time_end
        );
      } else if (type === "arrival") {
        filterTickets = data.filter(
          (e) => e.jam_tiba >= time_start && e.jam_tiba <= time_end
        );
      }
      dispatch({
        type: "FILTER_TICKETS",
        payload: {
          tickets: filterTickets,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
};

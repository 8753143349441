import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCircleInfo,
  faComment,
  faHouse,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function MobileNav() {
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {popup && (
        <div
          onClick={() => setPopup(false)}
          className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 flex justify-center items-center"
          style={{ zIndex: "10010" }}
        >
          <div className="w-full h-5/6 lg:h-2/3 max-h-max max-w-screen-md bg-white rounded-lg flex justify-center items-center relative">
            <FontAwesomeIcon
              onClick={() => setPopup(false)}
              className="hidden lg:absolute top-5 right-8 cursor-pointer"
              icon={faClose}
            />
            <frame
              id="iframe1"
              title="ContactMe"
              className="w-full h-full"
              src="https://us14.list-manage.com/contact-form?u=0207b20f842c1399de4b3b1f0&form_id=75bb8629abeac58156b5c1158f3034b9"
            />
          </div>
        </div>
      )}
      <div className="w-full h-20 flex items-center justify-evenly lg:hidden fixed bg-white border-y-2 z-10 bottom-0 left-0 font-medium text-lg color-navbar-dark-blue">
        <div
          onClick={() => {
            navigate("/");
          }}
          className=" flex flex-col gap-2 justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faHouse} />
          <p>Home</p>
        </div>
        <div
          onClick={() => setPopup(true)}
          className=" flex flex-col gap-2 justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faComment} />
          <p>Contact Us</p>
        </div>
        <div
          onClick={() => {
            navigate("/search");
          }}
          className=" flex flex-col gap-2 justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faCalendar} />
          <p>Schedule</p>
        </div>
        <div
          onClick={() => {
            navigate("/about-us");
          }}
          className=" flex flex-col gap-2 justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faCircleInfo} />
          <p>About Us</p>
        </div>
      </div>
    </>
  );
}

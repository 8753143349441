import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faCircleInfo,
  faComment,
  faHouse,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      {popup && (
        <div
          onClick={() => setPopup(false)}
          className="w-screen h-screen bg-black bg-opacity-50 fixed top-0 flex justify-center items-center"
          style={{ zIndex: "10012" }}
        >
          <div className="w-full h-2/3 max-h-max max-w-screen-md bg-white rounded-lg flex justify-center items-center relative">
            <FontAwesomeIcon
              onClick={() => setPopup(false)}
              className="absolute top-5 right-5 cursor-pointer"
              icon={faClose}
            />
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              className="w-full h-full"
              src="https://us14.list-manage.com/contact-form?u=0207b20f842c1399de4b3b1f0&form_id=75bb8629abeac58156b5c1158f3034b9"
            />
          </div>
        </div>
      )}
      <div className="w-full flex justify-between items-center border-y-2 px-10 sticky top-0 z-50 bg-white">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        <img
          src={process.env.PUBLIC_URL + "/assets/logo-budiman.png"}
          className="h-16 py-2"
          alt="logo budiman"
        />
        <div className="hidden lg:flex gap-10 font-medium text-lg color-navbar-dark-blue">
          <div
            onClick={() => {
              navigate("/");
            }}
            className="flex gap-2 justify-center items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faHouse} />
            <p>Home</p>
          </div>
          <div
            onClick={() => setPopup(true)}
            className="flex gap-2 justify-center items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faComment} />
            <p>Contact Us</p>
          </div>
          <div
            onClick={() => {
              navigate("/search");
            }}
            className="flex gap-2 justify-center items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faCalendar} />
            <p>Schedule</p>
          </div>
          <div
            onClick={() => {
              navigate("/about-us");
            }}
            className="flex gap-2 justify-center items-center cursor-pointer"
          >
            <FontAwesomeIcon icon={faCircleInfo} />
            <p>About Us</p>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

import BookingImg from "../../assets/booking.png";
import SupportImg from "../../assets/support.png";
import InformationImg from "../../assets/information.png";

export default function Benefit() {
  return (
    <div className="w-full lg:w-7/12 flex flex-col gap-10 justify-center px-8 lg:px-0 lg:mx-auto py-10">
      <div className="flex flex-col lg:flex-row w-full gap-4 lg:gap-16 px-2 lg:px-14">
        <img
          className="hidden lg:block w-10 lg:w-32 py-8"
          src={BookingImg}
          alt="booking images"
        />
        <div className="w-full flex flex-col gap-5 items-center justify-center">
          <h3 className="text-lg font-medium">Bebas Ribet</h3>
          <img
            className="lg:hidden w-20 py-8"
            src={BookingImg}
            alt="booking images"
          />
          <p className="font-light text-xs lg:text-base lg:font-medium text-justify">
            Bisa beli tiket di mana saja dan kapan saja dengan aplikasi budiman
            mobile ataupun di agen resmi bus budiman terdekat.
          </p>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row w-full gap-4 lg:gap-16 px-2 lg:px-14">
        <div className="w-full flex flex-col gap-5 items-center justify-center">
          <h3 className="text-lg font-medium">
            24 Jam Bantuan Layanan Pelangan
          </h3>
          <img
            className="lg:hidden w-20 py-8"
            src={SupportImg}
            alt="support images"
          />
          <p className="font-light text-xs lg:text-base lg:font-medium text-justify">
            Kami juga menyediakan layanan informasi via call center yang akan
            membantu anda silahkan hubungi 0265 345 772 / 0265 345 797
          </p>
        </div>
        <img
          className="hidden lg:block w-10 lg:w-32 py-8"
          src={SupportImg}
          alt="support images"
        />
      </div>
      <div className="flex flex-col lg:flex-row w-full gap-4 lg:gap-16 px-2 lg:px-14">
        <img
          className="hidden lg:block w-10 lg:w-32 py-8"
          src={InformationImg}
          alt="information images"
        />
        <div className="w-full flex flex-col gap-5 items-center justify-center">
          <h3 className="text-lg font-medium">Informasi</h3>
          <img
            className="lg:hidden w-20 py-8"
            src={InformationImg}
            alt="information images"
          />
          <p className="font-light text-xs lg:text-base lg:font-medium text-justify">
            Info terbaru tentang bus budiman bisa kalian dapatkan di seluruh
            sosial media kami budimanbusID dan website kami{" "}
            <a rel="noopener" href="https://www.budiman.co.id">
              budiman.co.id
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

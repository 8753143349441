import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat } from "@fortawesome/free-solid-svg-icons";
import { SearchCity } from "../../store/actions/CityActions";
import { SearchTickets } from "../../store/actions/TicketsActions";

export default function Searchbar(props) {
  const { city } = useSelector((state) => state.city);
  const { selectedFrom, selectedTo } = useSelector((state) => state.tickets);
  const [from, setFrom] = useState(
    props.flow === "search" ? selectedFrom.kota : ""
  );
  const [to, setTo] = useState(props.flow === "search" ? selectedTo.kota : "");
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(SearchCity());
  }, []);

  const SearchTicket = async () => {
    let fromObj = city.filter((e) => e.kota === from)[0];
    let toObj = city.filter((e) => e.kota === to)[0];
    let selectedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
    dispatch(SearchTickets(fromObj, toObj, selectedDate));
    navigate("/search");
    props.setOpenSearch(false);
  };

  return (
    <div
      onClick={() => props.setSearchBar(true)}
      className={`w-full lg:w-2/3 bg-white shadow-lg search-bar rounded-md pt-5 my-5 flex flex-col gap-4 pb-10 px-3 ${
        !props.searchBar ? " cursor-pointer" : ""
      }`}
      style={{ zIndex: "10011" }}
    >
      <h2 className="text-base font-semibold">
        Informasi cari jadwal dan harga tiket bus budiman
      </h2>
      <div className="w-full flex flex-col lg:flex-row lg:justify-between lg:items-center">
        <div className="w-full lg:w-5/12 flex flex-col gap-2">
          <h3 className="text-base font-medium">Dari</h3>
          <div className="w-full flex items-center border border-gray-400 rounded-md pl-1 overflow-hidden">
            <span className="material-icons text-gray-400">directions_bus</span>
            <input
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              className="p-1 w-full border-l border-gray-400"
              list="city_from"
            />
            <datalist id="city_from">
              {city.map((e, i) => {
                return <option value={e.kota} key={i} />;
              })}
            </datalist>
          </div>
        </div>
        <div
          onClick={() => {
            let temp = from;
            setFrom(to);
            setTo(temp);
          }}
          className="w-8 h-8 mx-auto lg:mx-0 color-dark-blue rounded-full flex justify-center items-center relative top-3 cursor-pointer"
        >
          <FontAwesomeIcon icon={faRepeat} className="text-white" />
        </div>
        <div className="w-full lg:w-5/12 flex flex-col gap-2">
          <h3 className="text-base font-medium">Ke</h3>
          <div className="w-full flex items-center border border-gray-400 rounded-md pl-1 overflow-hidden">
            <span className="material-icons text-gray-400">directions_bus</span>
            <input
              value={to}
              onChange={(e) => setTo(e.target.value)}
              className="p-1 w-full border-l border-gray-400"
              list="city_to"
            />
            <datalist id="city_to">
              {city.map((e, i) => {
                return <option value={e.kota} key={i} />;
              })}
            </datalist>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-between items-center">
        <div className="w-5/12 flex flex-col gap-2">
          <h3 className="text-base font-medium">Tanggal Pergi</h3>
          <div className="w-full flex items-center border border-gray-400 rounded-md pl-1">
            <span className="material-icons text-gray-400">calendar_month</span>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="h-full w-full"
              selected={date}
              isClearable
              closeOnScroll={true}
              minDate={new Date()}
              onChange={setDate}
            />
          </div>
        </div>
        <div className="w-5/12 flex flex-col gap-2">
          <h3 className="text-base font-medium min-height-22"></h3>
          <button
            onClick={() => {
              SearchTicket();
            }}
            className="w-full color-dark-blue flex justify-center p-1 items-center border border-gray-400 rounded-md"
          >
            <div className="flex gap-2">
              <span className="material-icons text-white">search</span>
              <div className="text-white">Cari Bus</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import {
  FilterTickets,
  SearchTickets,
} from "../../store/actions/TicketsActions";

export default function Sidebar({ tickets }) {
  const uniqueAddresses = new Set();

  const groupedData = tickets.reduce((acc, item) => {
    const key = `${item.brangkat}-${item.tujuan}`;

    // Hanya memasukkan agen jika alamatnya belum ada dalam uniqueAddresses
    if (!uniqueAddresses.has(item.alamat_brangkat)) {
      if (!acc[key]) {
        acc[key] = { brangkat: [], tujuan: [] };
      }

      acc[key].brangkat.push(item);
      uniqueAddresses.add(item.alamat_brangkat);
    }

    if (!uniqueAddresses.has(item.alamat_tujuan)) {
      if (!acc[key]) {
        acc[key] = { brangkat: [], tujuan: [] };
      }

      acc[key].tujuan.push(item);
      uniqueAddresses.add(item.alamat_tujuan);
    }

    return acc;
  }, {});

  const { selectedFrom, selectedTo, selectedDate } = useSelector(
    (state) => state.tickets
  );
  const dispatch = useDispatch();
  return (
    <div className="w-full lg:w-1/3 py-4 lg:py-10">
      <div className="w-full max-w-md bg-white rounded-lg">
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between items-end w-full px-4 py-2 text-xl font-medium text-left text-white color-dark-blue rounded-t-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-50">
                <span>Filters</span>
                <span
                  onClick={() => {
                    dispatch(
                      SearchTickets(selectedFrom, selectedTo, selectedDate)
                    );
                  }}
                  className="text-sm font-light underline"
                >
                  Reset
                </span>
                {/* <ChevronUpIcon
                      className={`${
                        open ? 'transform rotate-180' : ''
                      } w-5 h-5 text-white`}
                    /> */}
              </Disclosure.Button>
              {/* <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-700">
                    If you're unhappy with your purchase for any reason, email us
                    within 90 days and we'll refund you in full, no questions asked.
                  </Disclosure.Panel> */}
            </>
          )}
        </Disclosure>
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-lg font-medium text-left text-white color-dark-blue focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-50">
                <span>Departure Time</span>
                <ChevronUpIcon
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-700">
                <div class="space-y-4">
                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("departure", "00:00", "06:00"));
                      }}
                      id="dusk"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="dusk"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      00:00 - 06:00{" "}
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("departure", "06:00", "12:00"));
                      }}
                      id="morning"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="morning"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      06:00 - 12:00{" "}
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("departure", "12:00", "18:00"));
                      }}
                      id="afternoon"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="afternoon"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      12:00 - 18:00{" "}
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("departure", "18:00", "00:00"));
                      }}
                      id="night"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="night"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      18:00 - 00:00{" "}
                    </label>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-lg font-medium text-left text-white color-dark-blue focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-50">
                <span>Arrival Time</span>
                <ChevronUpIcon
                  className={`${
                    open ? "transform rotate-180" : ""
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-700">
                <div class="space-y-4">
                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("arrival", "00:00", "06:00"));
                      }}
                      id="dusk-arrival"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="dusk-arrival"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      00:00 - 06:00{" "}
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("arrival", "06:00", "12:00"));
                      }}
                      id="morning-arrival"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="morning-arrival"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      06:00 - 12:00{" "}
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("arrival", "12:00", "18:00"));
                      }}
                      id="afternoon-arrival"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="afternoon-arrival"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      12:00 - 18:00{" "}
                    </label>
                  </div>

                  <div class="flex items-center">
                    <input
                      onClick={() => {
                        dispatch(FilterTickets("arrival", "18:00", "00:00"));
                      }}
                      id="night-arrival"
                      name="notification-method"
                      type="radio"
                      class="focus:ring-indigo-500 cursor-pointer h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label
                      for="night-arrival"
                      class="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                    >
                      {" "}
                      18:00 - 00:00{" "}
                    </label>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
      <div className="w-full mt-10 max-w-md bg-white rounded-lg">
        {tickets.length ? (
          <>
            <div className="mb-5">
              <h2 className="text-lg font-bold text-gray-800 border-b border-gray-300 p-5">
                Informasi Agen
              </h2>
            </div>
            {Object.entries(groupedData).map(([key, value], index) => {
              const { brangkat, tujuan } = value;

              return (
                <div key={index} className="bg-white rounded-lg p-6 mb-6">
                  <h6 className="text-lg font-semibold text-gray-700 border-b border-gray-300 pb-2">
                    {key.split("-").join(" - ")}
                  </h6>
                  <div className="mt-4">
                    {brangkat.map((item, itemIndex) => (
                      <div
                        key={`brangkat-${itemIndex}`}
                        className="my-2 p-3 bg-gray-100 rounded-md"
                      >
                        <p className="font-medium text-gray-600">
                          Telp:{" "}
                          {item.tlp_agen_keberangkatan || "Tidak tersedia"}
                        </p>
                        <p className="font-medium text-gray-600">
                          Alamat Keberangkatan:
                        </p>
                        <p className="text-gray-500">{item.alamat_brangkat}</p>
                      </div>
                    ))}
                  </div>
                  <div className="mt-4">
                    {tujuan.map((item, itemIndex) => (
                      <div
                        key={`tujuan-${itemIndex}`}
                        className="my-2 p-3 bg-gray-100 rounded-md"
                      >
                        <p className="font-medium text-gray-600">
                          Telp: {item.tlp_agen_tujuan || "Tidak tersedia"}
                        </p>
                        <p className="font-medium text-gray-600">
                          Alamat Tujuan:
                        </p>
                        <p className="text-gray-500">{item.alamat_tujuan}</p>
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <p className="color-navbar-dark-blue m-4">
            Tidak ada tiket ditemukan...
          </p>
        )}
      </div>
    </div>
  );
}

const initialState = {
  city: [],
};

const cityReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_CITY":
      return {
        ...state,
        city: payload,
      };

    default:
      return state;
  }
};

export default cityReducers;

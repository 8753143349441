import { useState } from "react";
import Searchbar from "../components/Searchbar";
import Benefit from "../components/Benefit";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const newImages = [
  {
    id:1,
    img:"https://s3.ap-southeast-1.amazonaws.com/budiman.co.id/FirstPromo.jpg",
    desc:"Dapatkan harga menarik hanya di Aplikasi Budiman Mobile"
  },
  {
    id:2,
    img:"https://s3.ap-southeast-1.amazonaws.com/budiman.co.id/Tasikmalaya-Kutabumi.jpg",
    desc:"Telah beroperasi jurusan baru Tasikmalaya - Kutabumi"
  },
  {
    id: 3,
    img: "https://s3.ap-southeast-1.amazonaws.com/budiman.co.id/NewsPromoPoin_PROKES.jpg",
    desc:"Budayakan hidup sehat dengan tetap mematuhi protokol kesehatan"
  },
  {
    id: 4,
    img: "https://s3.ap-southeast-1.amazonaws.com/budiman.co.id/NewsPromoPoin_Vaksin.jpg",
    desc:"Seluruh karyawan kami sudah divaksin"
  },
  {
    id:5,
    img:"https://s3.ap-southeast-1.amazonaws.com/budiman.co.id/LebihHemat20k.jpg",
    desc: "Lebih Hemat 20 Ribu dengan menggunakan aplikasi budiman mobile"
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  const [searchBar, setSearchBar] = useState(false);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="min-h-full">
        <div className="flex flex-col">
          <main className="flex-1">
            {searchBar && (
              <div
                onClick={() => setSearchBar(false)}
                className="bg-black bg-opacity-70 w-screen h-full fixed top-0 cursor-pointer"
                style={{ zIndex: "10000" }}
              ></div>
            )}
            <div className="color-dark-blue h-96 flex justify-center items-center">
              <div className="px-4 sm:px-9 lg:px-8 flex w-full justify-center search-bar-container">
                <Searchbar
                  setSearchBar={setSearchBar}
                  searchBar={searchBar}
                  classNames={classNames}
                  flow={"home"}
                />
              </div>
            </div>
            <div className="w-full lg:w-3/4 mx-auto">
              <p className="text-2xl font-semibold p-2">Artikel Terbaru</p>
              <Carousel
                responsive={responsive}
                infinite={true}
                swipeable={true}
                draggable={true}
                autoPlaySpeed={2000}
              >
                {newImages.map((data) => {
                  return (
                    <div
                      className="container p-2 text-center cursor-pointer flex flex-wrap mx-auto"
                      key={data.id}
                    >
                      <div className="w-full p-2 rounded lg:w-3/3">
                        <img className="rounded-lg" src={data.img} alt="News" />
                      </div>
                      <div className="w-full text-justify py-2">
                        <p className="text-lg font-semibold w-full">
                          {data.desc}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <Benefit />
          </main>
        </div>
      </div>
    </>
  );
};

export default Home;

import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import cityReducers from "./reduces/CityReducers";
import ticketsReducers from "./reduces/TicketsReducers";

const rootReducer = combineReducers({
  city: cityReducers,
  tickets: ticketsReducers,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;

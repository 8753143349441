import instance from "../../config";

export const SearchCity = () => {
  return async (dispatch, getState) => {
    try {
      // const { city } = getState();
      const { data } = await instance.get("v2/city/group", {});
      dispatch({
        type: "FETCH_CITY",
        payload: data.group_city,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

const initialState = {
  tickets: [],
  selectedFrom: {},
  selectedTo: {},
  selectedDate: "",
};

const ticketsReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "FETCH_TICKETS":
      return {
        ...state,
        tickets: payload.tickets,
        selectedFrom: payload.from,
        selectedTo: payload.to,
        selectedDate: payload.date,
      };

    case "FILTER_TICKETS":
      return {
        ...state,
        tickets: payload.tickets,
      };

    default:
      return state;
  }
};

export default ticketsReducers;

import "react-app-polyfill/stable";
import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from "./utils/reportWebVitals";
import Home from "../src/views/Home.js";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import MobileNav from "./components/MobileNav";
import { Provider } from "react-redux";
import Search from "./views/Search";
import store from "./store";
import AboutUs from "./views/AboutUs";

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/search" element={<Search />}></Route>
      </Routes>
      <Footer />
      <MobileNav />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

function Accordion({
  berangkat,
  tujuan,
  tlp_agen_keberangkatan,
  alamat_brangkat,
  content,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="w-full flex justify-between border bg-white rounded p-3">
        <div className="accordion-header">
          <div className="accordion-title">
            {berangkat}{" "}
            <FontAwesomeIcon
              icon={faArrowRightLong}
              className="text-gray-500"
            />{" "}
            {tujuan}
          </div>
          <div className={`accordion-icon ${isOpen ? "open" : ""}`}></div>
        </div>
        <div onClick={toggleAccordion} className="mx-6">
          <button
            type="button"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm text-white shadow-sm color-dark-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          >
            Lihat Jadwal
          </button>
        </div>
      </div>

      {isOpen && <div>{content}</div>}
    </>
  );
}

export default Accordion;

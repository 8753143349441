import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faHome, faPhone } from "@fortawesome/free-solid-svg-icons";

export default function Footer() {
  return (
    <div className={`color-dark-blue w-full h-44 py-5 px-3 mb-20 lg:mb-0`}>
      <div className="w-full flex flex-col gap-5 justify-center items-center">
        <div className="flex gap-5">
          <div className="rounded-full w-auto flex justify-center items-center p-2 bg-white cursor-pointer">
            <FontAwesomeIcon icon={faHome} className="color-icon-dark-blue" />
          </div>
          <div className="rounded-full w-auto flex justify-center items-center p-2 bg-white cursor-pointer">
            <FontAwesomeIcon
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send?phone=+628112008045&text=Hai,%20PT%20HS%20Budiman%20saya%20melihat%20website%20budiman.co.id%20nih%20dan%20mau%20tanya-tanya%20soal%20Bus%20Budiman",
                  "_blank"
                )
              }
              icon={faPhone}
              className="color-icon-dark-blue"
            />
          </div>
          <div className="rounded-full w-auto flex justify-center items-center p-2 bg-white cursor-pointer">
            <FontAwesomeIcon
              onClick={() =>
                window.open(
                  "https://www.facebook.com/groups/budimanlovers",
                  "_blank"
                )
              }
              icon={faFacebookF}
              className="color-icon-dark-blue"
            />
          </div>
          <div className="rounded-full w-auto flex justify-center items-center p-2 bg-white cursor-pointer">
            <FontAwesomeIcon
              onClick={() =>
                window.open("https://www.instagram.com/budimanbusid/", "_blank")
              }
              icon={faInstagram}
              className="color-icon-dark-blue"
            />
          </div>
        </div>

        <div className="flex mt-12 text-white">
          <span className="material-icons">copyright</span>
          <h4>2023 Copyright: PT HS BUDIMAN 45</h4>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faBusSimple,
} from "@fortawesome/free-solid-svg-icons";
import Searchbar from "../components/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { SearchTickets } from "../store/actions/TicketsActions";
import Sidebar from "../components/Sidebar";
import Accordion from "./Accordion";
import Spinner from "../components/Spinner";

export default function Search() {
  const { tickets, selectedFrom, selectedTo, selectedDate } = useSelector(
    (state) => state.tickets
  );
  const [selectedTicket, setSelectedTicket] = useState(null);
  const dispatch = useDispatch();
  const [openSearch, setOpenSearch] = useState(false);
  const [fullDate, setFullDate] = useState("");
  const [searchBar, setSearchBar] = useState(false);
  useEffect(() => {
    if (
      !Object.keys(selectedFrom).length ||
      !Object.keys(selectedTo).length ||
      !selectedDate.length
    ) {
      let from = JSON.parse(localStorage.getItem("from"));
      let to = JSON.parse(localStorage.getItem("to"));
      let date = localStorage.getItem("date");
      dispatch(SearchTickets(from, to, date));
    }
  }, []);
  useEffect(() => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let currentDate = !selectedDate
      ? localStorage.getItem("date")
      : selectedDate;
    const d = new Date(currentDate);
    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();
    setFullDate(`${day}, ${date} ${month} ${year}`);
  });

  const timeSpend = (datang, berangkat) => {
    let datangSplit = datang.split(":");
    let berangkatSplit = berangkat.split(":");
    let minute = "0";
    let hour = "0";
    let result = "";
    if (+datangSplit[0] > +berangkatSplit[0]) {
      hour = +datangSplit[0] - +berangkatSplit[0];
    } else if (+datangSplit[0] < +berangkatSplit[0]) {
      hour = +datangSplit[0] + 24 - +berangkatSplit[0];
    }
    datangSplit[1] =
      datangSplit[1] === "00" && berangkatSplit[1] === "60"
        ? "60"
        : datangSplit[1];
    if (+datangSplit[1] > +berangkatSplit[1]) {
      minute = +datangSplit[1] - +berangkatSplit[1];
    } else if (+datangSplit[1] < +berangkatSplit[1]) {
      hour--;
      minute = +datangSplit[1] - +berangkatSplit[1];
      if (minute < 0) {
        minute *= -1;
      }
    }
    result =
      minute.toString() === "60" || minute.toString() === "0"
        ? `${hour}H`
        : `${hour}H ${minute}M`;
    return result;
  };

  const formatRupiah = (money) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(money);
  };

  const handleTicketClick = (ticket) => {
    setSelectedTicket(ticket);
  };

  const groupedData = tickets.reduce((acc, item) => {
    const key = `${item.brangkat}-${item.tujuan}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});

  return (
    <div
      className={`w-full h-full${
        !Object.keys(selectedFrom).length ||
        !Object.keys(selectedTo).length ||
        !selectedDate.length
          ? " min-h-search"
          : ""
      } flex flex-col gap-5 px-4 lg-px-0 py-4 lg:py-10 bg-gray`}
    >
      <div className="w-full flex flex-col">
        <div className="w-full lg:w-2/3 flex flex-col lg:flex-row justify-between mx-auto pb-5 border-b-2 p-5 gap-5">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <p className="text-lg font-semibold">
                {!selectedFrom.kota ? "Kota Asal" : selectedFrom.kota}
              </p>
              <FontAwesomeIcon
                icon={faArrowRightLong}
                className="text-gray-500"
              />
              <p className="text-lg font-semibold">
                {!selectedTo.kota ? "Kota Tujuan" : selectedTo.kota}
              </p>
            </div>
            <div>
              <p className="text-sm font-semibold">{fullDate}</p>
            </div>
          </div>
          <button
            onClick={() => {
              setOpenSearch(!openSearch);
            }}
            type="button"
            className="inline-flex justify-center items-center px-8 py-2 border border-transparent text-lg lg:text-sm font-semibold rounded-md shadow-sm text-white color-dark-blue"
          >
            Change City
          </button>
        </div>
        {openSearch && (
          <div className="col-span-4 flex justify-center items-center py-5">
            <Searchbar
              setSearchBar={setSearchBar}
              searchBar={searchBar}
              setOpenSearch={setOpenSearch}
              flow={"search"}
            />
          </div>
        )}
      </div>
      <div className="w-full flex justify-center mx-auto">
        <div className="w-full lg:w-2/3 flex flex-col lg:flex-row justify-center lg:gap-10 mx-auto">
          <Sidebar tickets={tickets} />
          <div
            className={`w-full lg:w-2/3 flex flex-col gap-4${
              !tickets.length ? " justify-center" : ""
            } py-4 lg:py-10`}
          >
            {tickets.length ? (
              <>
                {Object.keys(groupedData).map((key) => {
                  const group = groupedData[key];
                  return (
                    <Accordion
                      key={key}
                      berangkat={`${group[0].brangkat}`}
                      tujuan={`${group[0].tujuan}`}
                      alamat_brangkat={`${group[0].alamat_brangkat}`}
                      content={group.map((item) => (
                        <div
                          key={item.i}
                          className="w-full flex flex-col gap-8 rounded-1xl border mb-4 p-4 hover:-translate-y-1 delay-75 duration-100 bg-white"
                        >
                          <div className="flex w-full items-center gap-2">
                            <FontAwesomeIcon
                              icon={faBusSimple}
                              className="text-gray-700"
                            />
                            <p className="text-lg text-gray-700 font-semibold">
                              {" "}
                              {item.tipe_bus}{" "}
                            </p>
                          </div>
                          <div className="flex w-full">
                            <div className="flex w-2/3 justify-between items-center">
                              <div className="flex flex-col gap-2">
                                <p className="text-sm text-gray-700 font-semibold">
                                  {" "}
                                  {item.jam_berangkat}{" "}
                                </p>
                                <p className="text-md text-gray-500 font-base">
                                  {" "}
                                  {item.brangkat}{" "}
                                </p>
                              </div>
                              <FontAwesomeIcon
                                icon={faArrowRightLong}
                                className="text-gray-500"
                              />
                              <div className="flex flex-col gap-2">
                                <p className="text-sm text-gray-700 font-semibold">
                                  {" "}
                                  {item.jam_tiba}{" "}
                                </p>
                                <p className="text-md text-gray-500 font-base">
                                  {" "}
                                  {item.tujuan}{" "}
                                </p>
                              </div>
                              <div className="flex justify-center items-center pl-1 border-l-2 border-gray-400 h-2/3">
                                <p className="text-sm text-gray-500 font-semibold">
                                  {" "}
                                  {timeSpend(
                                    item.jam_tiba,
                                    item.jam_berangkat
                                  )}{" "}
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-center items-center w-1/3">
                              <p className="text-lg text-gray-700 font-semibold">
                                {" "}
                                {formatRupiah(item.harga)}{" "}
                                <span className="text-xs text-gray-400 font-base">
                                  / Kursi
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    />
                  );
                })}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

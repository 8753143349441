import React from "react";

const Spinner = () => {
  return (
    <div class="no-scroll">
      <div className="flex justify-center items-center">
        <div class="w-12 h-12 rounded-full animate-spin border-2 border-solid border-blue-500 border-t-transparent"></div>
      </div>
    </div>
  );
};

export default Spinner;
